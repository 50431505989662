import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Error from '../../../Components/Error';
import BookingService from '../../../Services/BookingService';
import format from '../../../Utils/dateFormat';
import getDriverStatusLabel from '../../../Utils/getDriverStatusLabel';

function DriverEventsModal({
  originalOrder = {}, show = false, handleClose,
}) {
  const [error, setError] = useState('');
  const [events, setEvents] = useState([]);

  const fetchEvents = async () => {
    setError('');
    try {
      const response = await BookingService.getDriverEvents(originalOrder);
      setEvents(response.data.data);
    } catch (err) {
      setError(err.message);
      console.error(err);
    }
  };

  const debouncedFetchevents = debounce(() => {
    fetchEvents();
  }, 500);

  useEffect(() => {
    debouncedFetchevents();
    return () => debouncedFetchevents.cancel();
  }, [originalOrder]);

  // Function to parse and format event metadata
  const parseEvent = (event) => ({
    action: event.entry.action.replace(/_/g, ' '), // Format action text
    date: format(new Date(event.Date)), // Format timestamp
    lat: event.entry.metadata.lat || 'N/A',
    lon: event.entry.metadata.lon || 'N/A',
    metadata: event.entry.metadata,
  });

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Driver Events</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} setError={setError} />

        {events.length === 0 ? (
          <p className="text-muted">No events available.</p>
        ) : (
          <ul className="timeline">
            {events.map((event, index) => {
              const parsedEvent = parseEvent(event);
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index} className="timeline-item">
                  <div className="timeline-icon">
                    <i className="bi bi-check-circle-fill text-success" />
                  </div>
                  <div className="timeline-content">
                    <h6 className="fw-bold">{parsedEvent.action}</h6>
                    {parsedEvent.action === 'NEW EVENT' && <p>{getDriverStatusLabel(parsedEvent.metadata.event_type)}</p>}
                    {(parsedEvent.action === 'NEW EVENT' && parsedEvent.metadata.car) && (
                    <p>
                      <span>
                        <b>Plate Number</b>
                        {' '}
                        {parsedEvent.metadata.car.plate_number}
                      </span>
                      <span className="ms-2">
                        <b>Type</b>
                        {' '}
                        {parsedEvent.metadata.car.type}
                      </span>
                    </p>
                    )}
                    {(parsedEvent.action === 'NEW EVENT' && parsedEvent.metadata.driver) && (
                    <p>
                      <span>
                        <b>Name</b>
                        {' '}
                        {parsedEvent.metadata.driver.full_name}
                      </span>
                      <span className="ms-2">
                        <b>Phone Number</b>
                        {' '}
                        {parsedEvent.metadata.driver.phone_number}
                      </span>
                    </p>
                    )}

                    {(parsedEvent.action === 'NEW EVENT' && parsedEvent.metadata.event_type === 5) && (
                      parsedEvent.metadata.no_show_urls.map((url) => (
                        <p><a href={url} target="_blank" rel="noreferrer">{url}</a></p>
                      ))
                    )}
                    <p className="text-muted">
                      {parsedEvent.date}
                      {' '}
                      • Local •
                      {parsedEvent.lat}
                      ,
                      {parsedEvent.lon}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default DriverEventsModal;
