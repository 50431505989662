import axios from 'axios';

const NOMINATIM_URL = 'https://nominatim.openstreetmap.org/search';

export default async function (address) {
  try {
    const response = await axios.get(NOMINATIM_URL, {
      params: { q: address, format: 'json' },
    });

    if (response.data && response.data.length > 0) {
      return {
        lat: response.data[0].lat,
        lon: response.data[0].lon,
      };
    }
  } catch (error) {
    console.error('Error fetching geolocation data:', error);
  }
  return { lat: null, lon: null };
}
