/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
  Button, Form, Modal, Table,
} from 'react-bootstrap';
import format from '../../../Utils/dateFormat';
import thousandSeparator from '../../../Utils/numberFormat';

function OrderInputPreview({
  order,
  setIsPreviewMode,
  loading,
  handleSubmit,
  partners,
}) {
  const [sendNotificationToCustomer, setSendNotificationToCustomer] = useState(false);

  const getPartnerName = (partnerId) => {
    const orderPartner = partners.find((partner) => partner.Id === partnerId);

    if (orderPartner) {
      return orderPartner.name;
    }

    return '';
  };

  return (
    <>
      <Table striped responsive>
        <tbody>
          <tr>
            <th className="bg-dark-subtle" colSpan={2}>Partner Information</th>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Partner Name
            </td>
            <td>{getPartnerName(order.partner_id)}</td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Partner #
            </td>
            <td>{order.partner_booking_reference}</td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Partner Cancellation Compensation
            </td>
            <td>
              {thousandSeparator(order.partner_cancellation_price)}
              {' '}
              {order.partner_cancellation_currency}
            </td>
          </tr>
          <tr>
            <th className="bg-dark-subtle" colSpan={2}>Passenger Information</th>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Full Name
            </td>
            <td>{order.full_name}</td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              E-mail address
            </td>
            <td>{order.email_address}</td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Phone Number
            </td>
            <td>{order.phone_number}</td>
          </tr>
          <tr>
            <th className="bg-dark-subtle" colSpan={2}>Travel Information</th>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Date
            </td>
            <td>{order.date_datetime && format(order.date_datetime)}</td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Return Date
            </td>
            <td>{order.return_date_datetime && format(order.return_date_datetime)}</td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              From
            </td>
            <td>{order.destination_from?.address}</td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              To
            </td>
            <td>{order.destination_to?.address}</td>
          </tr>
          <tr>
            <th className="bg-dark-subtle" colSpan={2}>Programme</th>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Programme Total Duration (h)
            </td>
            <td>{order.programme?.total_hours}</td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Programme Name
            </td>
            <td>{order.programme?.name}</td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Programme Itinerary
            </td>
            <td>{order.programme?.itinerary}</td>
          </tr>
          <tr>
            <th className="bg-dark-subtle" colSpan={2}>Other Information</th>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Flight Number
            </td>
            <td>{order.flight_number}</td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Comments
            </td>
            <td>{order.comments}</td>
          </tr>

          {/* Passengers */}
          <tr>
            <th className="bg-dark-subtle" colSpan={2}>Passengers</th>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Total Amount
            </td>
            <td>
              {order.passengers.amount}
            </td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Adults
            </td>
            <td>
              {order.passengers.adults}
            </td>
          </tr>
          <tr>
            <td className="bg-body-secondary">Convertible Seat</td>
            <td>
              {order.passengers.convertible_seat}
            </td>
          </tr>
          <tr>
            <td className="bg-body-secondary">Booster Seat</td>
            <td>
              {order.passengers.booster_seat}
            </td>
          </tr>
          <tr>
            <td className="bg-body-secondary">Infant Carrier</td>
            <td>
              {order.passengers.infant_carrier}
            </td>
          </tr>

          {/* Order Details */}
          <tr>
            <th className="bg-dark-subtle" colSpan={2}>Order Details</th>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Order Status
            </td>
            <td>
              {order.status}
            </td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Vehicle Category
            </td>
            <td>
              {order.category.name}
            </td>
          </tr>

          {/* Price */}
          <tr>
            <th className="bg-dark-subtle" colSpan={2}>Price</th>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Final Price
            </td>
            <td>
              {thousandSeparator(order.price)}
              {' '}
              {order.currency}
            </td>
          </tr>
          <tr>
            <td className="bg-body-secondary">
              Operator Cancellation Compensation
            </td>
            <td>
              {thousandSeparator(order.operator_cancellation_price)}
              {' '}
              {order.operator_cancellation_currency}
            </td>
          </tr>
        </tbody>
      </Table>

      <Modal.Footer>
        <Form.Group controlId="send_notification_to_customer" className="d-flex">
          <Form.Label>Send notification to customer</Form.Label>
          <Form.Check
            type="checkbox"
            className="ms-2 me-2"
            disabled={!order.email_address}
            checked={sendNotificationToCustomer}
            onChange={() => setSendNotificationToCustomer(!sendNotificationToCustomer)}
          />
        </Form.Group>

        <Button
          variant="secondary"
          onClick={() => setIsPreviewMode(false)}
        >
          Back to edit
        </Button>

        <Button
          onClick={() => handleSubmit(sendNotificationToCustomer)}
          disabled={loading}
        >
          <i className="fa fa-thumbs-up" />
          { ' ' }
          { order._id ? 'Modify' : 'Create' }
          { ' ' }
          { sendNotificationToCustomer && '& email' }
        </Button>
      </Modal.Footer>
    </>
  );
}

export default OrderInputPreview;
