/* eslint-disable no-nested-ternary */
import { Modal } from 'react-bootstrap';
import React from 'react';
import format from '../../../Utils/dateFormat';
import thousandSeparator from '../../../Utils/numberFormat';

function OrderDetailsModal({ order, show, handleClose }) {
  if (!order) return null;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Order Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container mt-4">
          <div className="order-details row g-3">

            { /* Partner Information */}
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Partner Information</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <strong>Name:</strong>
                    {' '}
                    {order.partner?.name}
                  </li>
                  <li className="list-group-item">
                    <strong>Partner booking #:</strong>
                    {' '}
                    {order.partner_booking_reference}
                  </li>
                  <li className="list-group-item">
                    <strong>Partner Cancellation Compensation:</strong>
                    {' '}
                    {order.partner_cancellation_price ?? 0}
                    {' '}
                    {order.partner_cancellation_currency || 'EUR'}
                  </li>
                </ul>
              </div>
            </div>

            { /* Operator Information */}
            {order.operator && (
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Operator</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <strong>Operator #:</strong>
                    {' '}
                    {order.operator_booking_reference}
                  </li>
                  <li className="list-group-item">
                    <strong>Operator:</strong>
                    {' '}
                    {order.operator?.name}
                  </li>

                  <li className="list-group-item">
                    <strong>Operator Comments:</strong>
                    {' '}
                    {order.operator_comments}
                  </li>

                  <li className="list-group-item">
                    <strong>Operator Price:</strong>
                    {' '}
                    {thousandSeparator(order.operator_price)}
                    {' '}
                    {order.operator_currency}
                  </li>

                  <li className="list-group-item">
                    <strong>Driver:</strong>
                    {' '}
                    {order.driver?.full_name}
                    {' '}
                    {order.driver?.phone_number}
                  </li>

                  <li className="list-group-item">
                    <strong>Car:</strong>
                    {' '}
                    {order.car?.type || ''}
                    {' - '}
                    {order.car?.plate_number || ''}
                    {' - '}
                    {order.car?.color || ''}
                  </li>

                  <li className="list-group-item">
                    <strong>Operator Cancellation Compensation:</strong>
                    {' '}
                    {order.operator_cancellation_price ?? 0}
                    {' '}
                    {order.operator_cancellation_currency || 'EUR'}
                  </li>
                </ul>
              </div>

            </div>
            ) }

            { /* Customer Information */}
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Passenger Information</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <strong>Name:</strong>
                    {' '}
                    {order.full_name}
                  </li>
                  <li className="list-group-item">
                    <strong>Email:</strong>
                    {' '}
                    {order.email_address}
                  </li>
                  <li className="list-group-item">
                    <strong>Phone:</strong>
                    {' '}
                    {order.phone_number}
                  </li>
                </ul>
              </div>
            </div>

            { /* Travel Information */}
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Travel Information</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <strong>From:</strong>
                    {' '}
                    {order.destination_from.address}
                  </li>
                  <li className="list-group-item">
                    <strong>To:</strong>
                    {' '}
                    {order.destination_to.address}
                  </li>
                  <li className="list-group-item">
                    <strong>Date:</strong>
                    {' '}
                    {format(order.date_datetime)}
                  </li>
                  {order.return_date_datetime && (
                  <li className="list-group-item">
                    <strong>Return:</strong>
                    {' '}
                    {format(order.return_date_datetime)}
                  </li>
                  )}
                </ul>
              </div>
            </div>

            { /* Travel Information */}
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Programme Information</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <strong>Programme Name:</strong>
                    {' '}
                    {order.programme?.name}
                  </li>
                  <li className="list-group-item">
                    <strong>Total Duration (h):</strong>
                    {' '}
                    {order.programme?.total_hours}
                  </li>
                  <li className="list-group-item">
                    <strong>Itinerary:</strong>
                    {' '}
                    {order.programme?.itinerary}
                  </li>
                </ul>
              </div>
            </div>

            { /*  Order Details */}
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Order Details</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <strong>Order Status:</strong>
                    {' '}
                    {order.status}
                  </li>
                  <li className="list-group-item">
                    <strong>Booking Reference:</strong>
                    {' '}
                    {order.booking_reference}
                  </li>
                  <li className="list-group-item">
                    <strong>Vehicle Category:</strong>
                    {' '}
                    {order.category.name}
                  </li>
                  <li className="list-group-item">
                    <strong>Capacity:</strong>
                    {' '}
                    {order.category.capacity}
                  </li>
                  <li className="list-group-item">
                    <strong>Max Luggage:</strong>
                    {' '}
                    {order.category.max_luggage}
                  </li>
                </ul>
              </div>

            </div>

            { /*  Flight and Comments */}
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Flight and Comments</div>
                <ul className="list-group list-group-flush">
                  {order.flight_number && (
                  <li className="list-group-item">
                    <strong>Flight Number:</strong>
                    {' '}
                    {order.flight_number}
                  </li>
                  )}
                  <li className="list-group-item">
                    <strong>Comments:</strong>
                    {' '}
                    {order.comments || 'None'}
                  </li>
                  <li className="list-group-item">
                    <strong>Admin Comments:</strong>
                    {' '}
                    {order.admin_comments || 'None'}
                  </li>
                </ul>
              </div>
            </div>

            { /*  Passengers */}
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Passengers</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <strong>Adults:</strong>
                    {' '}
                    {order.passengers.adults}
                  </li>
                  <li className="list-group-item">
                    <strong>Convertible Seats:</strong>
                    {' '}
                    {order.passengers.convertible_seat}
                  </li>
                  <li className="list-group-item">
                    <strong>Booster Seats:</strong>
                    {' '}
                    {order.passengers.booster_seat}
                  </li>
                  <li className="list-group-item">
                    <strong>Infant Carriers:</strong>
                    {' '}
                    {order.passengers.infant_carrier}
                  </li>
                  <li className="list-group-item">
                    <strong>Total Amount:</strong>
                    {' '}
                    {order.passengers.amount}
                  </li>
                </ul>
              </div>

            </div>

            { /*  Payment Information */}
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Payment Information</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <strong>Total Price:</strong>
                    {' '}
                    {thousandSeparator(order.total_price) ?? thousandSeparator(order.price)}
                    {' '}
                    {order.currency || 'EUR'}
                  </li>
                  <li className="list-group-item">
                    <strong>Base Price:</strong>
                    {' '}
                    {order.price}
                    {' '}
                    {order.currency || 'EUR'}
                  </li>
                  <li className="list-group-item">
                    <strong>Extras Price:</strong>
                    {' '}
                    {order.extra_price ?? 0}
                    {' '}
                    {order.currency || 'EUR'}
                  </li>
                  <li className="list-group-item">
                    <strong>Type:</strong>
                    {' '}
                    {order.payment?.type}
                  </li>
                  {order.discount?.code && (
                    <>
                      <li className="list-group-item">
                        <strong>Discount Code:</strong>
                        {' '}
                        {order.discount.code}
                      </li>
                      <li className="list-group-item">
                        <strong>Discount:</strong>
                        {' '}
                        {order.discount.percentage}
                        %
                      </li>
                    </>
                  )}
                  <li className="list-group-item">
                    <strong>Payment Status:</strong>
                    {' '}
                    {order.payment?.barion_payment_id || order.payment?.paid
                      ? <span className="badge bg-success">Paid to us</span>
                      : order.payment?.type === 'Cash'
                        ? <span className="badge bg-info">Waiting for payment from driver</span>
                        : order.payment?.type === 'Transfer to Partner'
                          ? <span className="badge bg-info">Waiting for payment from partner</span>
                          : <span className="badge bg-info">Pending</span>}
                  </li>
                </ul>
              </div>

            </div>

            { /* Billing Address */}
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Billing Address</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">{order.billing?.name}</li>
                  <li className="list-group-item">
                    {order.billing?.address}
                    ,
                    {' '}
                    {order.billing?.postcode}
                  </li>
                  <li className="list-group-item">
                    {order.billing?.city}
                    ,
                    {' '}
                    {order.billing?.country}
                  </li>
                </ul>
              </div>

            </div>

            { /* Metadata */}
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-header">Metadata</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <strong>Created At:</strong>
                    {' '}
                    {new Date(order.created_at).toLocaleString()}
                  </li>
                  <li className="list-group-item">
                    <strong>Last Updated:</strong>
                    {' '}
                    {new Date(order.updated_at).toLocaleString()}
                  </li>
                  <li className="list-group-item">
                    <strong>Source:</strong>
                    {' '}
                    {order.source?.domain}
                  </li>
                  <li className="list-group-item">
                    <strong>Created by:</strong>
                    {' '}
                    {order.source?.created_by}
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>

      </Modal.Body>
    </Modal>
  );
}

export default OrderDetailsModal;
