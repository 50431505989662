export default function getDriverStatusLabel(state) {
  switch (state) {
    case 1:
      return 'DRIVER DEPARTED TO PICKUP';
    case 2:
      return 'DRIVER ARRIVED AT PICKUP';
    case 3:
      return 'DRIVER DEPARTED TO DROPOFF';
    case 4:
      return 'FINISHED';
    case 5:
      return 'CUSTOMER NO SHOW';
    case 6:
      return 'DRIVER LIVE LOCATION';
    default:
      return '-';
  }
}
