/* eslint-disable no-underscore-dangle */
import { get, post } from './API';

export default {
  postBooking: async (order) => {
    // eslint-disable-next-line no-param-reassign
    order.passengers.amount = Number(order.passengers.adults)
        + Number(order.passengers.infant_carrier)
        + Number(order.passengers.booster_seat)
        + Number(order.passengers.convertible_seat);

    await post('/bookings', { ...order });
  },
  assignDriverAndCar: async (order) => {
    await post('/bookings/assign-driver-and-car', { ...order });
  },
  addInvoice: async (order) => {
    await post('/bookings/invoices', { ...order });
  },
  getDriverEvents: async (order) => get('/bookings/driver-events', { _id: order._id })
  ,
};
