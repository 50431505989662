import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import FormControlFeedback from '../../../Components/FormControlFeedback';
import fetchGeoLocation from '../../../Services/GeoService';

function AddressForm({
  fieldKey, input, handleChange, validationErrors,
}) {
  const [query, setQuery] = useState(_.get(input, fieldKey, ''));
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    if (query.length > 3) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(async () => {
          try {
            const { lat, lon } = await fetchGeoLocation(query);
            if (lat && lon) {
              handleChange({ target: { id: fieldKey.replace('address', 'latitude'), value: lat } });
              handleChange({ target: { id: fieldKey.replace('address', 'longitude'), value: lon } });
            }
          } catch (error) {
            console.error('Error fetching geolocation data:', error);
          }
        }, 500),
      );
    }
  }, [query]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    handleChange(e);
  };

  return (
    <>
      <tr>
        <th className="bg-dark-subtle" colSpan={2}>{_.startCase(fieldKey)}</th>
      </tr>
      <tr>
        <td className="bg-body-secondary">
          {_.startCase(fieldKey)}
          <i className="fa fa-asterisk text-danger bg-body-secondary" />
        </td>
        <td>
          <Form.Group controlId={fieldKey}>
            <Form.Control
              className="bg-warning-subtle"
              type="text"
              value={query}
              onChange={handleInputChange}
            />
            <FormControlFeedback errors={validationErrors} field={fieldKey} />
          </Form.Group>
        </td>
      </tr>
      <tr>
        <td className="bg-body-secondary">Latitude</td>
        <td>
          <Form.Group controlId={`${fieldKey.replace('address', 'latitude')}`}>
            <Form.Control
              className="bg-light"
              type="text"
              value={_.get(input, fieldKey.replace('address', 'latitude'), '')}
              readOnly
            />
          </Form.Group>
        </td>
      </tr>
      <tr>
        <td className="bg-body-secondary">Longitude</td>
        <td>
          <Form.Group controlId={`${fieldKey.replace('address', 'longitude')}`}>
            <Form.Control
              className="bg-light"
              type="text"
              value={_.get(input, fieldKey.replace('address', 'longitude'), '')}
              readOnly
            />
          </Form.Group>
        </td>
      </tr>
    </>
  );
}

export default AddressForm;
