/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import _ from 'lodash';
import { post } from '../../../Services/API';
import Error from '../../../Components/Error';
import FormControlFeedback from '../../../Components/FormControlFeedback';
import setStateFromInput from '../../../Utils/setStateFromInput';

function PartnerInputModal({
  originalPartner = {}, show = false, handleClose, fetchPartners,
}) {
  const defaultPartner = {
    active: 1,
    driver_portal_enabled: 0,
    ph_branding_allowed: 0,
    driver_events_queue: '',
    billing: {},
  };

  const [partner, setPartner] = useState(_.defaults(originalPartner, defaultPartner));
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await post('/bookings/partners', {
        ...partner,
        active: Number(partner.active),
        ph_branding_allowed: Number(partner.ph_branding_allowed),
        driver_portal_enabled: Number(partner.driver_portal_enabled),
      });
      fetchPartners();
      handleClose();
    } catch (err) {
      setError(err.response?.data?.message);

      if (err.response?.data?.errors) {
        setValidationErrors(err.response.data.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  const comentsTextareaRef = useRef(null);
  const paymentTermsTextareaRef = useRef(null);

  useEffect(() => {
    const comentsTextareaRefCurrent = comentsTextareaRef.current;
    if (comentsTextareaRefCurrent) {
      comentsTextareaRefCurrent.style.height = 'auto';
      comentsTextareaRefCurrent.style.height = `${comentsTextareaRefCurrent.scrollHeight}px`;
    }

    const paymentTermsTextareaRefCurrent = paymentTermsTextareaRef.current;
    if (paymentTermsTextareaRefCurrent) {
      paymentTermsTextareaRefCurrent.style.height = `${paymentTermsTextareaRefCurrent.scrollHeight}px`;
    }
  }, [partner]);

  /**
   * Handle input change
   * @param {object} e
   * @returns void
   */
  const handleChange = (e) => {
    setStateFromInput(e, setPartner, partner);
  };

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          { partner.Id && (
          <>
            Edit partner
            { ' ' }
            { partner.name }
          </>
          ) }
          { !partner.Id && (
          <>
            New partner
          </>
          ) }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Error
          error={error}
          setError={setError}
        />

        <Form>
          <Form.Group className="mb-3 col-12" controlId="name">
            <Form.Label>Name *</Form.Label>
            <Form.Control
              type="text"
              value={partner.name}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="name" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="website">
            <Form.Label>Website</Form.Label>
            <Form.Control
              type="text"
              value={partner.website}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="website" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={partner.email}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="email" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="phone_number">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              value={partner.phone_number}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="phone_number" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="billing.name">
            <Form.Label>Billing Name</Form.Label>
            <Form.Control
              type="text"
              value={partner.billing?.name}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="billing.name" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="billing.address">
            <Form.Label>Billing Address</Form.Label>
            <Form.Control
              type="text"
              value={partner.billing?.address}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="billing.address" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="billing.city">
            <Form.Label>Billing City</Form.Label>
            <Form.Control
              type="text"
              value={partner.billing?.city}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="billing.city" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="billing.postcode">
            <Form.Label>Billing Postcode</Form.Label>
            <Form.Control
              type="text"
              value={partner.billing?.postcode}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="billing.postcode" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="billing.country">
            <Form.Label>Billing Country</Form.Label>
            <Form.Control
              type="text"
              value={partner.billing?.country}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="billing.country" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="comments">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              value={partner.comments}
              onChange={handleChange}
              ref={comentsTextareaRef}
              style={{ overflow: 'hidden', resize: 'none' }}
            />
            <FormControlFeedback errors={validationErrors} field="comments" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="payment_terms">
            <Form.Label>Payment Terms</Form.Label>
            <Form.Control
              as="textarea"
              value={partner.payment_terms}
              ref={paymentTermsTextareaRef}
              style={{ overflow: 'hidden', resize: 'none' }}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="payment_terms" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="driver_portal_enabled">
            <Form.Label>Driver Portal Enabled</Form.Label>
            <Form.Select
              value={partner.driver_portal_enabled}
              onChange={handleChange}
            >
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </Form.Select>
            <FormControlFeedback errors={validationErrors} field="driver_portal_enabled" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="ph_branding_allowed">
            <Form.Label>Pickup Hungary Branding Allowed</Form.Label>
            <Form.Select
              value={partner.ph_branding_allowed}
              onChange={handleChange}
            >
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </Form.Select>
            <FormControlFeedback errors={validationErrors} field="ph_branding_allowed" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="parent_partner_id">
            <Form.Label>Parent Partner UUID</Form.Label>
            <Form.Control
              value={partner.parent_partner_id}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="parent_partner_id" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="driver_events_queue">
            <Form.Label>Driver Events Queue URL</Form.Label>
            <Form.Control
              value={partner.driver_events_queue}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="parent_partner_id" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="active">
            <Form.Label>Active</Form.Label>
            <Form.Select
              value={partner.active}
              onChange={handleChange}
            >
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </Form.Select>
            <FormControlFeedback errors={validationErrors} field="active" />
          </Form.Group>
        </Form>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit Partner
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default PartnerInputModal;
