/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import {
  Button,
  ButtonGroup,
} from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import fetchOrdersWrapper from '../Orders/Functions/fetchOrders';
import markAsCompletedWrapper from '../Orders/Functions/markAsCompleted';
import archiveOrderWrapper from '../Orders/Functions/archiveOrder';
import OrdersTable from '../Orders/Components/OrdersTable';
import useModalState from '../../Hooks/useModalState';
import Error from '../../Components/Error';
import format from '../../Utils/dateFormat';

dayjs.extend(utc);

function Trips() {
  // Create a new Date object for the current date and time
  const currentDate = new Date();

  const dateStartDefault = new Date(currentDate);
  dateStartDefault.setHours(currentDate.getHours() - 48);

  const dateEndDefault = new Date(currentDate);
  dateEndDefault.setHours(currentDate.getHours() + 72);

  const defaultFilters = {
    page: 1,
    status: ['Confirmed'],
    date: [format(dateStartDefault), format(dateEndDefault)],
    sort: { date_datetime: 'desc' },
  };

  const [orders, setOrders] = useState([]);

  const orderDetailsModal = useModalState();
  const operatorInputModal = useModalState();
  const orderInputModal = useModalState();
  const orderLineAdjustmentsModal = useModalState();
  const driverInputModal = useModalState();
  const tripConfirmationModal = useModalState();
  const pickupGuideModal = useModalState();
  const addInvoiceModal = useModalState();
  const driverEventsModal = useModalState();

  const [dateStart, setDateStart] = useState(dateStartDefault);
  const [dateEnd, setDateEnd] = useState(dateEndDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalTrips, setTotalTrips] = useState(0);

  const [filters, setFilters] = useState(defaultFilters);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const fetchOrders = () => fetchOrdersWrapper({
    setIsLoading,
    setOrders,
    setTotalPages,
    setError,
    setTotalOrders: setTotalTrips,
  })(filters);

  const markAsCompleted = (order) => markAsCompletedWrapper({
    setIsLoading,
    fetchOrders,
  })(order);

  const archiveOrder = (order) => archiveOrderWrapper({
    setIsLoading,
    fetchOrders,
    setError,
  })(order);

  const debouncedFetchOrders = _.debounce((appliedFilters) => {
    fetchOrders(appliedFilters);
  }, 500);

  const sortFn = (column, desc = true) => {
    setFilters({ ...filters, sort: { [column]: desc ? 'desc' : 'asc' }, page });
  };

  const filterDateByYesterday = () => {
    setDateStart(dayjs().utc().subtract(1, 'day').startOf('day'));
    setDateEnd(dayjs().utc().subtract(1, 'day').endOf('day'));
  };

  const filterDateByToday = () => {
    setDateStart(dayjs().utc().startOf('day'));
    setDateEnd(dayjs().utc().endOf('day'));
  };

  const filterDateByTomorrow = () => {
    setDateStart(dayjs().utc().add(1, 'day').startOf('day'));
    setDateEnd(dayjs().utc().add(1, 'day').endOf('day'));
  };

  useEffect(() => {
    const appliedFilters = { ...filters };
    // Call the debounced version within useEffect
    debouncedFetchOrders(appliedFilters);

    // Cleanup function to cancel any pending debounced call if the component
    // unmounts or before the next effect runs
    return () => debouncedFetchOrders.cancel();
  }, [filters]);

  useEffect(() => {
    setFilters((previousState) => (
      { ...previousState, date: [format(dateStart), format(dateEnd)] }
    ));
  }, [dateStart, dateEnd]);

  useEffect(() => {
    setFilters((previousState) => ({ ...previousState, page }));
  }, [page]);

  if (error) {
    return (
      <Error message={error} />
    );
  }

  return (
    <>
      <div className="d-block d-md-flex gap-1 w-100">
        <Button onClick={fetchOrders}><i className={`fa fa-sync ${isLoading ? 'fa-spin' : ''}`} /></Button>

        <div className="ms-auto d-flex gap-4">
          <p className="mt-2">
            <span className="fa fa-calendar-days me-2" />
            <span className="text-decoration-underline">Show only trips for</span>
          </p>
          <ButtonGroup>
            <Button variant="secondary" size="sm" onClick={filterDateByYesterday}>Yesterday</Button>
            <Button variant="secondary" size="sm" onClick={filterDateByToday}>Today</Button>
            <Button variant="secondary" size="sm" onClick={filterDateByTomorrow}>Tomorrow</Button>
          </ButtonGroup>
        </div>

      </div>

      <div className="card d-none d-lg-block position-fixed end-0 bottom-0">
        <div className="card-body">
          <div className="table table-primary">
            <thead>
              <th className="pe-3">Trips</th>
              <th className="pe-3">Start</th>
              <th className="pe-3">End</th>
            </thead>
            <tbody>
              <tr>
                <td>{totalTrips}</td>
                <td>{format(dateStart)}</td>
                <td>{format(dateEnd)}</td>
              </tr>
            </tbody>
          </div>
        </div>
      </div>

      <OrdersTable
        columns={
            {
              partner: true,
              partner_booking_reference: true,
              operator: true,
              operator_price: true,
              driver: true,
              customer_name: true,
              phone_number: true,
              transfer_date: true,
              from: true,
              category: false,
              flight_number: true,
              pax: true,
              to: true,
              price: true,
              status: false,
              warnings: true,
              trip_status: true,
              actions: {
                orders: true,
              },
            }
        }
        orders={orders}
        page={page}
        totalPages={totalPages}
        setFilters={setFilters}
        archiveOrder={archiveOrder}
        isLoading={isLoading}
        fetchOrders={debouncedFetchOrders}
        markAsCompleted={markAsCompleted}
        setPage={setPage}
        orderLineAdjustmentsModal={orderLineAdjustmentsModal}
        orderInputModal={orderInputModal}
        orderDetailsModal={orderDetailsModal}
        operatorInputModal={operatorInputModal}
        driverInputModal={driverInputModal}
        tripConfirmationModal={tripConfirmationModal}
        pickupGuideModal={pickupGuideModal}
        addInvoiceModal={addInvoiceModal}
        driverEventsModal={driverEventsModal}
        sortFn={sortFn}
      />
    </>
  );
}

export default Trips;
